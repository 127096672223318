import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { PromoCodeRoutingModule } from "./promo-code-routing.module";
import { PromoCodeMotorComponent } from "./motor/motor.component";
import { PromoCodeDealerComponent } from "./dealer/dealer.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MaterialModule } from "../material-module";
import { NgxMatSelectSearchModule } from "ngx-mat-select-search";
import { UploadFileComponent } from "./_components/upload-file/upload-file.component";
import { StatusDialogComponent } from "./_components/status-dialog/status-dialog.component";
import { NgxSkeletonLoaderModule } from "ngx-skeleton-loader";
import { EmptyRecordModule } from "../share/component/empty-record/empty-record.module";
import { PromoCodeMotorService } from "./motor/services/promo-code-motor.service";
import { PromoCodeDealerService } from "./dealer/services/promo-code-dealer.service";
import { SharedComponentModule } from "../share/component/shared-component.module";
@NgModule({
  entryComponents: [PromoCodeMotorComponent, PromoCodeDealerComponent],
  declarations: [
    PromoCodeMotorComponent,
    PromoCodeDealerComponent,
    UploadFileComponent,
    StatusDialogComponent,
  ],
  providers: [PromoCodeMotorService, PromoCodeDealerService],
  imports: [
    CommonModule,
    PromoCodeRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    NgxMatSelectSearchModule,
    NgxSkeletonLoaderModule,
    EmptyRecordModule,
    SharedComponentModule,
  ],
})
export class PromoCodeModule {}
