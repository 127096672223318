import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { AuthService } from "../../share/service/auth.service";

@Component({
	selector: "app-forgot-password",
	templateUrl: "./forgot-password.component.html",
})
export class ForgotPasswordComponent implements OnInit {
	success: boolean;
	formGroup: FormGroup;

	constructor(authService: AuthService) {
		if (authService.isLoggedIn()) {
			location.href = "/dashboard";
		}
	}

	ngOnInit(): void {
		this.formGroup = new FormGroup({
			userName: new FormControl(null, [Validators.required]),
		});
	}

	reset() {
		this.success = true;
	}
}
