import { Component, OnInit, Output, EventEmitter, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { Observable, of } from 'rxjs';
import { ECommerceDataService } from '../../data-service/e-commerce/e-commerce.data.service';
import { finalize, map, tap } from 'rxjs/operators';
import { ShopeeEWPolicyDataService } from '../../data-service/extended-warranty/shopee.data.service';
import { triggerFileDownload } from '../../helpers/downloadFile.helper';
import { LoadingService } from '../../service/loading.service';
import { UploadLogFileInterface } from '../../interface/extended-warranty/shopee.interface';
import { Sort } from '@angular/material/sort';

@Component({
  selector: 'app-policy-cancellation-log',
  templateUrl: './policy-cancellation-log.component.html',
  styleUrls: ['./policy-cancellation-log.component.scss']
})


export class PolicyCancellationLogComponent implements OnInit {

  tableData$: Observable<PolicyCancellationLogDataInterface[]> = of([])
  length$: Observable<number>;

	limit: number = 5;
	offset: number = 1;
	sortIndex: number = 0;
	sortDirection: "asc" | "desc" = "desc";

  @Output() pageChanged: EventEmitter<{limit: number, offset: number}> = new EventEmitter();

  constructor( 
    private ecommerceDataService: ECommerceDataService, 
    private shopeeEwPolicyService: ShopeeEWPolicyDataService,
    private loadingService: LoadingService,
    @Inject(MAT_DIALOG_DATA) public data: {
      type: string;
    }
  ){}

  ngOnInit(): void {
    this.getLogData();
  }

  downloadFile(data: UploadLogFileInterface){
    this.loadingService.loadingOn(true);
    this.ecommerceDataService.downloadById(data?.shopeeDocumentId)
			.pipe(
				tap((res) => {
					triggerFileDownload(res, data?.fileName);
				}),
				finalize(() => this.loadingService.loadingOff(true)),
			)
			.subscribe();
  }

  getLogData() {
    let data = {
			limit: this.limit,
			offset: this.offset,
			text: null,
			columnIndex: this.sortIndex,
			sortDirection: this.sortDirection,
			isSuccess: null,
			startDate: null,
			endDate: null
		}

    let tableData$;

    if(this.data?.type == 'damage_protection') {
      tableData$ = this.ecommerceDataService.getPolicyBulkCancelLogListing(data)
        .pipe();
    } 
    if(this.data?.type == 'extended_warranty') {
      tableData$ = this.shopeeEwPolicyService.getPolicyBulkCancelLogListing(data)
        .pipe();
    }

    this.tableData$ = tableData$.pipe(map((x: PolicyCancellationLogInterface) => x.data));
    this.length$ = tableData$.pipe(map((x: PolicyCancellationLogInterface) => x.count));
  }

  pageChange(e: PageEvent) {
		this.offset = e.pageIndex + 1;
		this.limit = e.pageSize;
    this.getLogData();
	}

  sortChange(sortState: Sort) {
		this.sortDirection = null;
		this.sortIndex = -1;
		if (sortState.direction) {
			this.sortDirection = sortState.direction;
			this.sortIndex = this.cancellationLogColumns.findIndex(
				(x) => x == sortState.active,
			);
		}
		if (!this.tableData$) return;

		this.getLogData();
	}


  cancellationLogColumns = [
    "date_uploaded",
    "file_name",
    "status",
    "uploaded_by"
  ]
}

interface PolicyCancellationLogDataInterface {
  createdDate: string;
  fileName: string;
  isSuccess: string;
  createdBy: string;
}

interface PolicyCancellationLogInterface {
  count: number;
  data: PolicyCancellationLogDataInterface[];
}