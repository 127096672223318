<div class="login-page">
	<div class="login-form">
		<form [formGroup]="formGroup" (ngSubmit)="reset()">
			<mat-card class="form-field">
				<img src="assets/image/logo.svg" class="login-logo" alt="" />
				<div class="sign-in-text">Forgot Password</div>

				<mat-card-content>
					<div class="email-sent-note" *ngIf="success">
						A temporary password has been send to your email.
					</div>

					<mat-form-field *ngIf="!success">
						<mat-label>Enter your login Id</mat-label>
						<input
							class="uppercase"
							matInput
							required
							formControlName="userName"
						/>
						<mat-error>Login ID is required</mat-error>
					</mat-form-field>

					<div>
						<div
							class="forgot-password-link"
							routerLink="/authentication/login"
						>
							Sign In Now
						</div>
						<section style="opacity: 0">1</section>
					</div>
					<button mat-raised-button color="primary" class="lg" *ngIf="!success">
						Send Recovery Email
					</button>
				</mat-card-content>
			</mat-card>
		</form>
	</div>
	<div class="crm-info">
		<div>
			<div class="title">Welcome to PolicyStreet CRM</div>
			<div class="desc">
				Turn your sales process into a state of the art, revenue-generating
				machine. Manage Your Pipeline with Total Visibility
			</div>
		</div>
	</div>
</div>
