import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { PromoCodeMotorComponent } from "./motor/motor.component";
import { PromoCodeDealerComponent } from "./dealer/dealer.component";

const routes: Routes = [
  {
    path: "motor",
    component: PromoCodeMotorComponent,
  },
  {
    path: "dealer",
    component: PromoCodeDealerComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class PromoCodeRoutingModule {}
