import { Injectable } from "@angular/core";
import { NgxSpinnerService } from "ngx-spinner";
import { BehaviorSubject, Observable } from "rxjs";

@Injectable({
	providedIn: "root",
})
export class LoadingService {
	private loadingSubject = new BehaviorSubject<boolean>(false);
	loading$: Observable<boolean> = this.loadingSubject.asObservable();

	constructor(private ngxSpinner: NgxSpinnerService) {}

	loadingOn(showSpinner?: boolean) {
		this.loadingSubject.next(true);
		if (showSpinner) this.ngxSpinner.show();
	}

	loadingOff(hideSpinner?: boolean) {
		this.loadingSubject.next(false);
		if (hideSpinner) this.ngxSpinner.hide();
	}
}
