import { Component } from "@angular/core";
import { SwUpdate } from "@angular/service-worker";
import { environment } from "../environments/environment";
import { AlertService } from "./share/service/alert.service";

@Component({
	selector: "app-root",
	templateUrl: "./app.component.html",
})
export class AppComponent {
	constructor(swUpdate: SwUpdate, alertService: AlertService) {
		if (environment.production) {
			swUpdate.available.subscribe((event) => {
				alertService.openSnackBar(
					"A newer version is now available. Refresh the page now to update the cache",
				);
				location.reload();
			});
			swUpdate.checkForUpdate();
		}
	}
}
