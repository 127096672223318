<mat-toolbar class="page-header">
  <div class="menu-container">
    <div>
      <div class="menu-title">Promo Code Generation</div>
      <div class="sub-title">(B2B/B2C/B2B2C)</div>
    </div>

    <div class="button-group">
      <button
        mat-flat-button
        color="primary"
        (click)="downloadTemplate('promo_code_template_motor')"
      >
        Download Template
      </button>
      <button mat-flat-button color="primary" (click)="uploadFile()">
        Upload
      </button>
      <button mat-flat-button color="primary" (click)="openStatus()">
        Log
      </button>
    </div>
  </div>
</mat-toolbar>

<div class="content-container">
  <div class="content-sub-header">
    <div class="filter-container">
      <mat-form-field
        appearance="outline"
        floatLabel="never"
        class="filter-wrapper"
      >
        <input
          matInput
          placeholder="Search here..."
          autocomplete="off"
          [formControl]="search"
        />
        <button
          mat-icon-button
          matSuffix
          class="clear-field-btn"
          (click)="resetTextFilter()"
          *ngIf="search.value"
        >
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>

      <mat-form-field
        appearance="outline"
        floatLabel="never"
        class="filter-wrapper"
      >
        <mat-select
          [(ngModel)]="filterForm.creationDate"
          placeholder="Creation Date"
        >
          <mat-select-trigger>
            <ng-container [ngSwitch]="filterForm.creationDate">
              <span *ngSwitchCase="'All'">All Time(Creation Date)</span>
              <span *ngSwitchCase="'Year'">This Year(Creation Date)</span>
              <span *ngSwitchCase="'Month'">This Month(Creation Date)</span>
              <span *ngSwitchCase="'Week'">This Week(Creation Date)</span>
              <span *ngSwitchCase="'Today'">Today(Creation Date)</span>

              <span *ngSwitchDefault>
                <span *ngIf="creationEndDate">
                  {{ creationStartDate | date : "dd/MM/yy" }}
                  - {{ creationEndDate | date : "dd/MM/yy" }}
                </span>
              </span>
            </ng-container>
          </mat-select-trigger>
          <mat-option value="Today" (click)="changeDate('CREATION', 'Today')">
            Today
          </mat-option>
          <mat-option value="Week" (click)="changeDate('CREATION', 'Week')">
            This Week
          </mat-option>
          <mat-option value="Month" (click)="changeDate('CREATION', 'Month')">
            This Month
          </mat-option>
          <mat-option value="Year" (click)="changeDate('CREATION', 'Year')">
            This Year
          </mat-option>
          <mat-option value="All" (click)="changeDate('CREATION', 'All')">
            All Time
          </mat-option>
          <mat-option value="Custom" (click)="creationPicker.open()">
            Custom Range
          </mat-option>
          <!-- NEW DATE PICKER UI  -->
          <mat-date-range-input
            style="display: none"
            [rangePicker]="creationPicker"
          >
            <input
              matStartDate
              [(ngModel)]="filterForm.creationStartDate"
              (dateChange)="
                onStartDateSelected('CREATION', $event.target.value)
              "
              placeholder="Start date"
            />
            <input
              matEndDate
              [(ngModel)]="filterForm.creationEndDate"
              (dateChange)="onEndDateSelected('CREATION', $event.target.value)"
              placeholder="End date"
            />
          </mat-date-range-input>
          <mat-date-range-picker
            #creationPicker
            (opened)="onStartDateOpen()"
            (closed)="onDateRangePickerClosed('CREATION')"
          >
          </mat-date-range-picker>
        </mat-select>
        <button
          mat-icon-button
          matSuffix
          class="clear-field-btn"
          (click)="resetDateFilter('creationDate')"
          *ngIf="filterForm.creationDate !== null"
        >
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>

      <mat-form-field
        appearance="outline"
        floatLabel="never"
        class="filter-wrapper"
      >
        <mat-select
          multiple
          [(ngModel)]="selectedBusiness"
          (ngModelChange)="onSelectedOptionsChange()"
        >
          <mat-select-trigger>
            {{ getMultiFilterLabel("selectedBusiness") }}
          </mat-select-trigger>
          <mat-option>
            <ngx-mat-select-search
              placeholderLabel="Search Business Type..."
              [ngModelOptions]="{ standalone: true }"
              [searching]="!businessTypeList.length"
              [showToggleAllCheckbox]="true"
              (toggleAll)="toggleSelectAll($event, 'selectedBusiness')"
              [toggleAllCheckboxChecked]="isSelectedAll('selectedBusiness')"
              [toggleAllCheckboxIndeterminate]="
                isSelectedOptionsIndeterminate('selectedBusiness')
              "
              (ngModelChange)="onSelectSearchChange($event, 'selectedBusiness')"
              noEntriesFoundLabel="No matching business type found"
              ngModel
              ngDefaultControl
            >
            </ngx-mat-select-search>
          </mat-option>
          <mat-option *ngFor="let item of filterBusinessList" [value]="item">
            {{ item }}
          </mat-option>
        </mat-select>
        <button
          mat-icon-button
          matSuffix
          class="clear-field-btn"
          *ngIf="!isSelectedAll('selectedBusiness')"
          (click)="resetFilteredOption($event, 'selectedBusiness')"
        >
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>

      <mat-form-field
        appearance="outline"
        floatLabel="never"
        class="filter-wrapper"
      >
        <mat-select
          [(ngModel)]="filterForm.effectiveDate"
          placeholder="Effective Date"
        >
          <mat-select-trigger>
            <ng-container [ngSwitch]="filterForm.effectiveDate">
              <span *ngSwitchCase="'All'">All Time(Effective Date)</span>
              <span *ngSwitchCase="'Year'">This Year(Effective Date)</span>
              <span *ngSwitchCase="'Month'">This Month(Effective Date)</span>
              <span *ngSwitchCase="'Week'">This Week(Effective Date)</span>
              <span *ngSwitchCase="'Today'">Today(Effective Date)</span>

              <span *ngSwitchDefault>
                <span *ngIf="effectiveEndDate">
                  {{ effectiveStartDate | date : "dd/MM/yy" }}
                  - {{ effectiveEndDate | date : "dd/MM/yy" }}
                </span>
              </span>
            </ng-container>
          </mat-select-trigger>
          <mat-option value="Today" (click)="changeDate('EFFECTIVE', 'Today')">
            Today
          </mat-option>
          <mat-option value="Week" (click)="changeDate('EFFECTIVE', 'Week')">
            This Week
          </mat-option>
          <mat-option value="Month" (click)="changeDate('EFFECTIVE', 'Month')">
            This Month
          </mat-option>
          <mat-option value="Year" (click)="changeDate('EFFECTIVE', 'Year')">
            This Year
          </mat-option>
          <mat-option value="All" (click)="changeDate('EFFECTIVE', 'All')">
            All Time
          </mat-option>
          <mat-option value="Custom" (click)="effectivePicker.open()">
            Custom Range
          </mat-option>
          <!-- NEW DATE PICKER UI  -->
          <mat-date-range-input
            style="display: none"
            [rangePicker]="effectivePicker"
          >
            <input
              matStartDate
              [(ngModel)]="filterForm.effectiveStartDate"
              (dateChange)="
                onStartDateSelected('EFFECTIVE', $event.target.value)
              "
              placeholder="Start date"
            />
            <input
              matEndDate
              [(ngModel)]="filterForm.effectiveEndDate"
              (dateChange)="onEndDateSelected('EFFECTIVE', $event.target.value)"
              placeholder="End date"
            />
          </mat-date-range-input>
          <mat-date-range-picker
            #effectivePicker
            (opened)="onStartDateOpen()"
            (closed)="onDateRangePickerClosed('EFFECTIVE')"
          >
          </mat-date-range-picker>
        </mat-select>
        <button
          mat-icon-button
          matSuffix
          class="clear-field-btn"
          (click)="resetDateFilter('effectiveDate')"
          *ngIf="filterForm.effectiveDate !== null"
        >
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>

      <mat-form-field
        appearance="outline"
        floatLabel="never"
        class="filter-wrapper"
      >
        <mat-select
          multiple
          [(ngModel)]="selectedStatus"
          (ngModelChange)="onSelectedOptionsChange()"
        >
          <mat-select-trigger>
            {{ getMultiFilterLabel("selectedStatus") }}
          </mat-select-trigger>
          <mat-option>
            <ngx-mat-select-search
              placeholderLabel="Search Status..."
              [ngModelOptions]="{ standalone: true }"
              [searching]="!statusOptions.length"
              [showToggleAllCheckbox]="true"
              (toggleAll)="toggleSelectAll($event, 'selectedStatus')"
              [toggleAllCheckboxChecked]="isSelectedAll('selectedStatus')"
              [toggleAllCheckboxIndeterminate]="
                isSelectedOptionsIndeterminate('selectedStatus')
              "
              (ngModelChange)="onSelectSearchChange($event, 'selectedStatus')"
              noEntriesFoundLabel="No matching status found"
              ngModel
              ngDefaultControl
            >
            </ngx-mat-select-search>
          </mat-option>
          <mat-option *ngFor="let item of filterStatusList" [value]="item">
            {{ item }}
          </mat-option>
        </mat-select>
        <button
          mat-icon-button
          matSuffix
          class="clear-field-btn"
          *ngIf="!isSelectedAll('selectedStatus')"
          (click)="resetFilteredOption($event, 'selectedStatus')"
        >
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>
    </div>
    <button
      appCheckFunctionPermission
      mat-flat-button
      color="primary"
      class="custom-button"
      (click)="onExport('excel_listing_motor')"
    >
      Export to Excel
    </button>
  </div>

  <mat-card class="card-table">
    <div class="table-container">
      <table
        mat-table
        [dataSource]="list"
        matSort
        (matSortChange)="sortChange($event)"
        matSortActive="date"
        matSortDisableClear
        matSortDirection="asc"
      >
        <ng-container matColumnDef="creationDate">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            Creation Date
          </th>
          <td mat-cell *matCellDef="let element" class="md-col">
            {{ element.createdDate | date : "dd MMM yyyy" }} <br />
            {{ element.createdDate | date : "hh:mm aa" }}
          </td>
        </ng-container>
        <ng-container matColumnDef="businessType">
          <th mat-header-cell *matHeaderCellDef>Business Type</th>
          <td mat-cell *matCellDef="let element" class="md-col">
            {{ element.businessType || "-" }}
          </td>
        </ng-container>
        <ng-container matColumnDef="promoName">
          <th mat-header-cell *matHeaderCellDef>Promo Name</th>
          <td mat-cell *matCellDef="let element" class="md-col">
            {{ element.name || "-" }}
          </td>
        </ng-container>

        <ng-container matColumnDef="promoCode">
          <th mat-header-cell *matHeaderCellDef>Promo Code</th>
          <td mat-cell *matCellDef="let element" class="md-col">
            {{ element.code }}
          </td>
        </ng-container>

        <ng-container matColumnDef="effectiveStartDate">
          <th mat-header-cell *matHeaderCellDef>Effective Start Date</th>
          <td mat-cell *matCellDef="let element" class="md-col">
            {{ element.effectiveStartDate | date : "dd MMMM yyyy" : "UTC" }}
          </td>
        </ng-container>
        <ng-container matColumnDef="effectiveEndDate">
          <th mat-header-cell *matHeaderCellDef>Effective End Date</th>
          <td mat-cell *matCellDef="let element" class="md-col">
            {{ element.effectiveEndDate | date : "dd MMM yyyy" : "UTC" }}
          </td>
        </ng-container>
        <ng-container matColumnDef="quantity">
          <th mat-header-cell *matHeaderCellDef>Quantity</th>
          <td mat-cell *matCellDef="let element" class="sm-col">
            {{ element.quantity || "-" }}
          </td>
        </ng-container>

        <ng-container matColumnDef="discountPercent">
          <th mat-header-cell *matHeaderCellDef>Discount (%)</th>
          <td mat-cell *matCellDef="let element" class="sm-col">
            {{ element.discountPercent ? element.discountPercent + " %" : "-" }}
          </td>
        </ng-container>

        <ng-container matColumnDef="discountAmount">
          <th mat-header-cell *matHeaderCellDef>Discount Amount</th>
          <td mat-cell *matCellDef="let element" class="sm-col">
            {{ element.discountAmount ? "RM " + element.discountAmount : "-" }}
          </td>
        </ng-container>

        <ng-container matColumnDef="status">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
          <td mat-cell *matCellDef="let element" class="md-col">
            <div [ngSwitch]="element.status">
              <span class="cover-label red" *ngSwitchCase="'Deactivated'"
                >Deactivated</span
              >
              <span class="cover-label green" *ngSwitchCase="'Active'"
                >Active</span
              >
              <span class="cover-label orange" *ngSwitchDefault>Expired</span>
            </div>
          </td>
        </ng-container>

        <ng-container matColumnDef="remarks">
          <th mat-header-cell *matHeaderCellDef>Remarks</th>
          <td mat-cell *matCellDef="let element" class="lg-col">
            {{ element.remarks || "-" }}
          </td>
        </ng-container>
        <ng-container matColumnDef="action">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let element">
            <button
              mat-icon-button
              [matMenuTriggerFor]="menu"
              (click)="selected = element; $event.stopPropagation()"
              [disabled]="
                element.status === 'Deactivated' || element.status === 'Expired'
              "
            >
              <mat-icon>more_vert</mat-icon>
            </button>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>

        <tr *matNoDataRow>
          <td class="mat-cell" colspan="9999">
            <app-empty-record
              *ngIf="!isLoading && list.length === 0"
              [disableSubTitle]="true"
            >
            </app-empty-record>
            <app-skeleton-loader *ngIf="isLoading"> </app-skeleton-loader>
          </td>
        </tr>
      </table>
    </div>

    <mat-divider></mat-divider>

    <mat-paginator
      [pageSizeOptions]="[50, 100, 150]"
      [pageSize]="limit"
      [length]="length"
      (page)="pageChange($event)"
      aria-label="Select page of users"
    ></mat-paginator>
  </mat-card>
</div>

<mat-menu #menu="matMenu">
  <button mat-menu-item (click)="deactivateUser()">Deactivate</button>
</mat-menu>
