<div class="pc-log-dialog">
	<div class="pc-log-dialog__title">
		<div mat-dialog-title class="pc-log-dialog__title__main">Policy Cancellation</div>
		<button
			mat-dialog-close
			class="pc-log-dialog__title__btn"
		>
			<mat-icon>close</mat-icon>
		</button>
	</div>
	<mat-divider></mat-divider>
	<div>
        <div class="pc-log-dialog__desc">Download the <span class="download-template" (click)="downloadTemplate()">template here</span> and upload the required file to cancel the policy.</div>
        <div class="pc-log-dialog__card">
            <div class="pc-log-dialog__card__title">
                <div calss="pc-log-dialog__card__title__main">Require File</div>

                <button mat-stroked-button color="primary">
                    <label for="uploads" class="label-btn"> Upload </label>
                </button>

                <!-- upload input -->
                <input
                    type="file"
                    id="uploads"
                    style="display: none"
                    formControlName="file"
                    accept=".xls, .xlsx, application/vnd.google-apps.document"
                    disable="fileToUpload"
                    (change)="uploadFile($event)"
                />
    
            </div>
 
            <!-- uploaded file name -->
            <div *ngIf="fileToUpload" class="pt-sm">
                <mat-divider></mat-divider>
                <div class="file-name">
                    <span class="underlined-blue">
                        {{ fileToUpload.name }}
                    </span>
                    <button mat-icon-button (click)="removeSelectedFile()" type="button">
                        <mat-icon>cancel</mat-icon>
                    </button>
                </div>
            </div>
        </div>
        <div class="pc-log-dialog__btn-container">
            <button mat-stroked-button color="primary" class="mr-sm" mat-dialog-close>
                Cancel
            </button>
            <button mat-flat-button color="primary" (click)="submitFile()"
                [disabled]="!fileToUpload"
            >
               Continue
            </button>
        </div>
	</div>
</div>
