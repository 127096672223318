import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../../../environments/environment";
import {
	EcomPoliciesListing,
	EcomPolicyClaimListing,
	EcomPolicyDetails,
} from "../../interface/ecommerce/ecommerce.interface";
import { CommonSelectionInterface } from "../../interface/common.interface";
import { Observable } from "rxjs";

@Injectable({
	providedIn: "root",
})
export class ECommerceDataService {
	private basePathPolicy: string = `${environment.url}/api/ShopeePolicy/`;
	private basePathDocument: string = `${environment.url}/api/ShopeeDocument/`;

	constructor(private http: HttpClient) {}

	// Shopee Policy
	getListing(
		limit: number,
		offset: number,
		text: string,
		columnIndex: number,
		sortDirection: string,
		startDate: Date,
		endDate: Date,
		claimStatusList?: string[],
		policyStatusList?: string[],
		groupPolicyNoList?: string[],
	) {
		return this.http.post<{
			count: number;
			data: EcomPoliciesListing[];
		}>(`${this.basePathPolicy}GetListing`, {
			limit,
			offset,
			text,
			columnIndex,
			sortDirection,
			claimStatusList,
			policyStatusList,
			groupPolicyNoList,
			startDate,
			endDate,
		});
	}

	getClaimTypeList(): CommonSelectionInterface[] {
		return [
			{
				id: "All Risks - Accidental Damage/Fire/Flood",
				name: "All Risks - Accidental Damage/Fire/Flood",
			},
			{ id: "Lightning Damage", name: "Lightning Damage" },
			{ id: "Loss due to Theft/Burglary", name: "Loss due to Theft/Burglary" },
			{ id: "Wear and Tear", name: "Wear and Tear" },
		];
	}

	getClaimStatusList(): CommonSelectionInterface[] {
		return [
			{ id: "New", name: "New" },
			{ id: "Processing", name: "Processing" },
			{ id: "Pending Client", name: "Pending Client" },
			{ id: "Pending Insurer", name: "Pending Insurer" },
			{ id: "Approved", name: "Approved" },
			{ id: "Rejected", name: "Rejected" },
			{ id: "Disbursed", name: "Disbursed" },
		];
	}

	getPolicyStatusList(): CommonSelectionInterface[] {
		return [
			{ id: "Active", name: "Active" },
			{ id: "Cancelled", name: "Cancelled" },
			{ id: "Expired", name: "Expired" },
			{ id: "Claimed", name: "Claimed" },
		];
	}

	deactivatePolicy(id: string, values: any) {
		return this.http.post(
			`${this.basePathPolicy}DeactivatePolicy/${id}`,
			values,
		);
	}

	getPolicyClaimDetail(id: string) {
		return this.http.get<EcomPolicyDetails>(
			`${this.basePathPolicy}GetPolicyClaimDetail/${id}`,
		);
	}

	getPolicyClaimListing(
		id: string,
		limit: number,
		offset: number,
		text: string,
		columnIndex: number,
		sortDirection: string,
		claimStatusList: string[],
		claimTypeList: string[],
	) {
		return this.http.post<{ count: number; data: EcomPolicyClaimListing[] }>(
			`${this.basePathPolicy}GetPolicyClaimListing/${id}`,
			{
				limit,
				offset,
				text,
				columnIndex,
				sortDirection,
				claimTypeList,
				claimStatusList,
			},
		);
	}

	getPolicyListingExcel(
		limit: number,
		offset: number,
		text: string,
		columnIndex: number,
		sortDirection: string,
		claimStatusList: string[],
		policyStatusList: string[],
		groupPolicyNoList: string[],
		startDate: Date,
		endDate: Date,
	) {
		return this.http.post(
			`${this.basePathPolicy}GetPolicyListingExcel`,
			{
				limit,
				offset,
				text,
				columnIndex,
				sortDirection,
				claimStatusList,
				policyStatusList,
				groupPolicyNoList,
				startDate,
				endDate,
			},
			{
				responseType: "blob",
			},
		);
	}

	editPhoneNo(id: string, phone: string){
		return this.http.post(`${this.basePathPolicy}EditPhoneNo/${id}`, {
			phone
		});
	}

	getPolicyBulkCancelLogListing(
		data: {
			limit: number,
			offset: number,
			text: string,
			columnIndex: number,
			sortDirection: string,
			isSuccess: boolean,
			startDate: Date,
			endDate: Date
		}
	) {
		return this.http.post<{ count: number; data: any[] }>(
			`${this.basePathPolicy}GetPolicyBulkCancelLogListing`,
			data,
		);
	}

	uploadPolicyBulkCancelExcel(data: File) {
		let formData: FormData = new FormData();
		formData.append('File', data); 

		return this.http.post(
			`${this.basePathPolicy}UploadPolicyBulkCancelExcel`,
			formData,
			{ responseType: "blob" }
		);
	}

	downloadPolicyBulkCancelExcelTemplate(): Observable<any> {
		return this.http.get<Blob>(
			`${this.basePathPolicy}DownloadPolicyBulkCancelExcelTemplate`,
			// @ts-ignore
			{ responseType: "blob" },
		);
	}

	// End Shopee Policy

	getClaimStatus() {
		return ["New", "Processing", "Approved", "Rejected", "Disbursed"];
	}

	// Shopee Document
	downloadById(id: string) {
		return this.http.get(`${this.basePathDocument}DownloadById/${id}`, {
			responseType: "blob",
		});
	}
	// End Shopee Document
}
