<div class="img-dialog">
  <div class="img-dialog__header">
    <button mat-icon-button (click)="onCloseDialog()">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <div class="img-dialog__content">
    <img [src]="img" alt="" />
  </div>
</div>
