<div>
	<div mat-dialog-title>{{data?.title}}</div>
	<mat-divider></mat-divider>
	<div mat-dialog-content>
		{{data?.content}}
		<span class="fw-black">{{data?.boldContent}}</span> {{data?.nextContent}}
		<span *ngIf="!data?.hideQuestionMark">?</span>
	</div>
	<div mat-dialog-actions>
		<button
			mat-button
			class="sm"
			[mat-dialog-close]="false"
			*ngIf="!data.hideCancelBtn"
		>
			{{data?.cancelBtnText ?? "Cancel"}}
		</button>
		<button
			mat-flat-button
			[mat-dialog-close]="true"
			color='{{data?.btnColor ?? "warn"}}'
			class="sm"
			(click)="onConfirm()"
		>
			{{data?.okBtnText ?? "Ok"}}
		</button>
	</div>
</div>
