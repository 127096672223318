import { Injectable } from "@angular/core";
import jwt_decode from "jwt-decode";

@Injectable({
	providedIn: "root",
})
export class AuthService {
	private tokenKey = "token";

	setToken(token: string) {
		localStorage.setItem("bearerToken", token);
		// document.cookie = `${this.tokenKey}=${token};path=/;`;
	}
	logout() {
		localStorage.removeItem("getTokenScreenClaims");
		localStorage.removeItem("getTokenProductClaims");
		localStorage.removeItem("getTokenFunctionClaims");
		localStorage.removeItem("bearerToken");
		// document.cookie = `${this.tokenKey}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
		window.location.reload();
	}

	getToken() {
		return localStorage.getItem("bearerToken");
		// return this.getCookie(this.tokenKey);
	}

	getUserId() {
		let token = this.getToken();
		// let token = this.getToken();
		let decoded = jwt_decode(token);
		return decoded[
			"http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier"
		];
	}

	isTokenExpired(): boolean {
		let token = this.getToken();
		if (!token) return false;
		let decoded = jwt_decode(token);
		let expireTime = new Date(decoded["exp"] * 1000);

		return expireTime < new Date();
	}

	isLoggedIn(): boolean {
		if (this.getToken()) {
			return !this.isTokenExpired();
		}
		return false;
	}

	// private getCookie(cname): string | null {
	// 	var name = cname + "=";
	// 	var decodedCookie = decodeURIComponent(document.cookie);
	// 	var ca = decodedCookie.split(";");
	// 	for (var i = 0; i < ca.length; i++) {
	// 		var c = ca[i];
	// 		while (c.charAt(0) == " ") {
	// 			c = c.substring(1);
	// 		}
	// 		if (c.indexOf(name) == 0) {
	// 			return c.substring(name.length, c.length);
	// 		}
	// 	}
	// 	return null;
	// }
}
