import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { NgxSpinnerService } from "ngx-spinner";
import { AuthService } from "../../share/service/auth.service";
import { AlertService } from "../../share/service/alert.service";
import { UserDataService } from "src/app/share/data-service/user.data.service";

@Component({
	selector: "app-login",
	templateUrl: "./login.component.html",
})
export class LoginComponent implements OnInit {
	hide: boolean = true;
	formGroup: FormGroup;

	constructor(
		private authService: AuthService,
		private userDataService: UserDataService,
		private ngxSpinnerService: NgxSpinnerService,
		private alertService: AlertService,
	) {
		if (authService.isLoggedIn()) {
			location.href = "/motor/quotations";
		}
	}

	ngOnInit(): void {
		this.formGroup = new FormGroup({
			email: new FormControl(null, [Validators.required, Validators.email]),
			password: new FormControl(null, [Validators.required]),
		});
	}

	login() {
		if (this.formGroup.invalid) return;
		let value = this.formGroup.value;
		this.ngxSpinnerService.show();
		this.userDataService.login(value.email, value.password).subscribe({
			next: (x) => {
				this.authService.setToken(x);
				this.userDataService.getUserAccessRights().subscribe({
					next: (y) => {
						localStorage.setItem("getTokenFunctionClaims", Object.values(y)[2]);
						localStorage.setItem("getTokenProductClaims", Object.values(y)[0]);
						localStorage.setItem("getTokenScreenClaims", Object.values(y)[1]);
						this.ngxSpinnerService.hide();

						location.href = "/settings/myaccount";
					},
					error: (err) => {
						this.ngxSpinnerService.hide();
						this.alertService.openSnackBar(err.error);
					},
				});
			},
			error: (err) => {
				this.ngxSpinnerService.hide();
				this.alertService.openSnackBar(err.error);
			},
		});
	}
}
