import { Clipboard } from "@angular/cdk/clipboard";
import { AlertService } from "src/app/share/service/alert.service";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { Component, Inject } from "@angular/core";
import {
  MatBottomSheetRef,
  MAT_BOTTOM_SHEET_DATA,
} from "@angular/material/bottom-sheet";
import { FileFunction } from "src/app/share/function/file.function";
import { LoadingService } from "src/app/share/service/loading.service";
import { PromoCodeMotorService } from "../../motor/services/promo-code-motor.service";
import { ActivatedRoute } from "@angular/router";
import { PromoCodeDealerService } from "../../dealer/services/promo-code-dealer.service";
import { NgxSpinnerService } from "ngx-spinner";
@Component({
  selector: "app-upload-file",
  templateUrl: "./upload-file.component.html",
  styleUrls: ["./upload-file.component.scss"],
})
export class UploadFileComponent {
  frmGroup: FormGroup;
  errorMessage: string = "";
  partnerGroupData: any;
  uploadedFile: any;
  partnerGroupList: any[] = [];

  constructor(
    private clipboard: Clipboard,
    private alertService: AlertService,
    private promoCodeMotorService: PromoCodeMotorService,
    private promoCodeDealerService: PromoCodeDealerService,
    private ngxSpinnerService: NgxSpinnerService,
    private bottomSheetRef: MatBottomSheetRef<UploadFileComponent>,
    private route: ActivatedRoute,
    @Inject(MAT_BOTTOM_SHEET_DATA)
    private data: {
      type: string;
    },
  ) {}

  deleteFile() {
    this.uploadedFile = null;
  }

  fileChangeEvent(e) {
    if (e.target.files && e.target.files[0]) {
      const file: File = e.target.files[0];

      // Check if the file type is Excel (.xlsx or .xls)
      const allowedTypes = [
        "application/vnd.ms-excel",
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      ];
      if (!allowedTypes.includes(file.type)) {
        this.alertService.openSnackBar("Only Excel files are allowed.");
        return;
      }

      this.uploadedFile = file;
    }
    e.target.value = "";
  }

  close(event: MouseEvent): void {
    this.bottomSheetRef.dismiss();
    event.preventDefault();
  }

  onUpload() {
    this.ngxSpinnerService.show();
    const formData = new FormData();
    formData.append("File", this.uploadedFile);
    let serviceType;

    if (this.data.type === "motor") {
      serviceType = this.promoCodeMotorService;
    } else {
      serviceType = this.promoCodeDealerService;
    }
    serviceType.uploadExcel(formData).subscribe(
      (x) => {
        if (x.size != 0) {
          let dataType = x.type;
          let binaryData = [];
          binaryData.push(x);
          let downloadLink = document.createElement("a");
          downloadLink.href = window.URL.createObjectURL(
            new Blob(binaryData, { type: dataType }),
          );
          downloadLink.setAttribute("download", "error");

          document.body.appendChild(downloadLink);
          downloadLink.click();
        } else {
          this.alertService.openSnackBar("File uploaded successfully.");
        }
        this.uploadedFile = null;
        this.ngxSpinnerService.hide();
        this.bottomSheetRef.dismiss({ success: true });
      },
      (error) => {
        this.ngxSpinnerService.hide();
        console.error("Error uploading file:", error);
        this.alertService.openSnackBar("Error uploading file.");
      },
    );
  }
}
