import { Router } from "@angular/router";
import { Component, OnInit } from "@angular/core";
import { AuthService } from "../../share/service/auth.service";
import { NAV_MENU_LIST } from "src/app/share/constants/navigation-list";
import {
	NavMenuInterface,
	NavMenuLevelTwoInterface,
} from "src/app/share/interface/common.interface";

@Component({
	selector: "app-drawer",
	templateUrl: "./drawer.component.html",
	styleUrls: ["./drawer.component.scss"],
})
export class AppDrawerComponent implements OnInit {
	tab: string;
	drawer_menu: NavMenuInterface[];
	product_list: string[];
	screen_list: string[];

	constructor(private router: Router, private authService: AuthService) {
		this.product_list = localStorage
			.getItem("getTokenProductClaims")
			.split(",");
		this.screen_list = localStorage.getItem("getTokenScreenClaims").split(",");
	}

	ngOnInit() {
		this.tab = this.router.url.split("/")[1];
	}

	//This function checks if user access grants them permission to view the main tab
	//access level of null represents a tab that all users should be able to view
	filterLevelOneAccess() {
		const navLevelOneArray = NAV_MENU_LIST;
		let allowableLevelOneAccess = navLevelOneArray.filter(
			(x: NavMenuInterface) =>
				this.product_list.includes(x.levelOneId) || x.levelOneId === null,
		);

		return allowableLevelOneAccess;
	}

	//This function checks if user access grants them permission to view the tab pages
	//access level of null represents a page that all users should be able to view
	filterLevelTwoAccess(routes: NavMenuLevelTwoInterface[]) {
		let allowableLevelTwoAccess = routes.filter(
			(x: NavMenuLevelTwoInterface) =>
				this.screen_list.includes(x.levelTwoId) || x.levelTwoId === null,
		);

		return allowableLevelTwoAccess;
	}

	logout() {
		this.authService.logout();
	}
}
