import { NgModule } from "@angular/core";
import { Routes, RouterModule, ExtraOptions } from "@angular/router";
import { AppBlankComponent } from "./layouts/blank/blank.component";
import { AppFullComponent } from "./layouts/full/full.component";
import { AuthGuard } from "./share/service/guard.service";
import { PromoCodeModule } from "./promo-code-generation/promo-code-generation.module";

const routerOptions: ExtraOptions = {
  anchorScrolling: "enabled",
  scrollOffset: [0, 64],
};

const routes: Routes = [
  {
    path: "",
    canActivate: [AuthGuard],
    component: AppFullComponent,
    children: [
      {
        path: "",
        redirectTo: "/settings/myaccount",
        pathMatch: "full",
      },
      {
        path: "dashboard",
        loadChildren: () =>
          import("./dashboard/dashboard.module").then((m) => m.DashboardModule),
      },
      {
        path: "motor",
        loadChildren: () =>
          import("./motor/motor.module").then((m) => m.MotorModule),
      },
      {
        path: "dealer",
        loadChildren: () =>
          import("./dealer/dealer.module").then((m) => m.DealerModule),
      },
      {
        path: "claims",
        loadChildren: () =>
          import("./claims/claims.module").then((m) => m.ClaimsModule),
      },
      {
        path: "employeebenefits",
        loadChildren: () =>
          import("./employee-benefits/employee-benefits.module").then(
            (m) => m.EmployeeBenefitsModule,
          ),
      },
      {
        path: "damageprotection",
        loadChildren: () =>
          import("./damage-protection/damage-protection.module").then(
            (m) => m.DamageProtectionModule,
          ),
      },
      {
        path: "extendedwarranty",
        loadChildren: () =>
          import("./extended-warranty/extended-warranty.module").then(
            (m) => m.ExtendedWarrantyModule,
          ),
      },
      {
        path: "extendedwarrantybs",
        loadChildren: () =>
          import("./extended-warranty/extended-warranty.module").then(
            (m) => m.ExtendedWarrantyModule,
          ),
      },
      // {
      // 	path: "template",
      // 	loadChildren: () =>
      // 		import("./template/template.module").then((m) => m.TemplateModule),
      // },
      // {
      // 	path: "ecommerce",
      // 	loadChildren: () =>
      // 		import("./ecommerce/ecommerce.module").then((m) => m.EcommerceModule),
      // },
      {
        path: "report",
        loadChildren: () =>
          import("./report/report.module").then((m) => m.ReportModule),
      },
      {
        path: "promo-code",
        loadChildren: () => PromoCodeModule,
      },

      {
        path: "settings",
        loadChildren: () =>
          import("./settings/settings.module").then((m) => m.SettingsModule),
      },
      {
        path: "accounts",
        loadChildren: () =>
          import("./accounts/accounts.module").then((m) => m.AccountsModule),
      },
      {
        path: "rideraccident",
        loadChildren: () =>
          import("./rider-accident/rider-accident.module").then(
            (m) => m.RiderAccidentModule,
          ),
      },
    ],
  },
  {
    path: "",
    component: AppBlankComponent,
    children: [
      {
        path: "authentication",
        loadChildren: () =>
          import("./authentication/authentication.module").then(
            (m) => m.AuthenticationModule,
          ),
      },
    ],
  },
  {
    path: "**",
    redirectTo: "authentication/404",
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: "enabled",
      anchorScrolling: "enabled",
      scrollOffset: [0, 64],
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
