<div class="wrapper">
	<!-- <app-header></app-header> -->
	<mat-drawer-container class="main-content">
		<mat-drawer
			#drawer
			[autoFocus]="false"
			[opened]="screenWidth > 1020"
			[mode]="screenWidth > 1020 ? 'side' : 'over'"
			class="drawer-content"
		>
			<app-drawer></app-drawer>
		</mat-drawer>
		<mat-drawer-content cdkScrollable>
			<app-header></app-header>
			<div class="page-container">
				<router-outlet></router-outlet>
			</div>
		</mat-drawer-content>
	</mat-drawer-container>
</div>
