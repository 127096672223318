import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import {CountBarComponent} from "./count-bar/count-bar.component";
import {MaterialModule} from "../../material-module";
import {NgxSkeletonLoaderModule} from "ngx-skeleton-loader";
import {MotorRoutingModule} from "../../motor/motor-routing.module";
import {DashboardRoutingModule} from "../../dashboard/dashboard-routing.module";
import {FlexLayoutModule} from "@angular/flex-layout";
import {MatBottomSheetModule} from "@angular/material/bottom-sheet";
import {NgxMatSelectSearchModule} from "ngx-mat-select-search";
import {NgxMatDatetimePickerModule, NgxMatTimepickerModule} from "@angular-material-components/datetime-picker";
import {NgxMatMomentModule} from "@angular-material-components/moment-adapter";
import {EmptyRecordModule} from "./empty-record/empty-record.module";
import {NgxMaskModule} from "ngx-mask";
import {NgxMatIntlTelInputModule} from "ngx-mat-intl-tel-input";
import {CustomDirectiveModule} from "../directive/custom-directive.module";
import {CustomPipeModule} from "../pipes/custom-pipe.module";
import { ImageDialogComponent } from './image-upload/image-dialog/image-dialog.component';

@NgModule({
    declarations: [
        CountBarComponent,
        ImageDialogComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        MaterialModule,
        NgxSkeletonLoaderModule,
        FlexLayoutModule,
        MatBottomSheetModule,
        FormsModule,
        ReactiveFormsModule,
        NgxMatSelectSearchModule,
        NgxMatDatetimePickerModule,
        NgxMatTimepickerModule,
        NgxMatMomentModule,
        NgxSkeletonLoaderModule,
        NgxMaskModule,
        NgxMatIntlTelInputModule,
    ],
    exports: [CountBarComponent]
})
export class SharedComponentModule {}
