<div>
  <div class="header">
    <div mat-dialog-title>{{ data?.title }}</div>
    <button mat-icon-button [mat-dialog-close]="true">
      <mat-icon>closed</mat-icon>
    </button>
  </div>
  <mat-divider></mat-divider>
  <div mat-dialog-actions>
    <div class="table-container">
      <table
        mat-table
        [dataSource]="list"
        matSort
        (matSortChange)="sortChange($event)"
        matSortActive="date"
        matSortDisableClear
        matSortDirection="asc"
      >
        <ng-container matColumnDef="uploadDate">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Upload Date</th>
          <td mat-cell *matCellDef="let element" class="md-col">
            {{ element.uploadDate | date : "dd MMM yyyy" }} <br />
            {{ element.uploadDate | date : "hh:mm aa" }}
          </td>
        </ng-container>
        <ng-container matColumnDef="fileName">
          <th mat-header-cell *matHeaderCellDef>File Name</th>
          <td mat-cell *matCellDef="let element" class="md-col">
            {{ element.fileName || "-" }}
          </td>
        </ng-container>

        <ng-container matColumnDef="status">
          <th mat-header-cell *matHeaderCellDef>Status</th>
          <td mat-cell *matCellDef="let element" class="md-col">
            <div [ngSwitch]="element.status">
              <span class="cover-label red" *ngSwitchCase="'Failed'">{{
                element.status
              }}</span>
              <span class="cover-label green" *ngSwitchCase="'Success'">{{
                element.status
              }}</span>
            </div>
          </td>
        </ng-container>

        <ng-container matColumnDef="uploadBy">
          <th mat-header-cell *matHeaderCellDef>Upload By</th>
          <td mat-cell *matCellDef="let element" class="lg-col">
            {{ element.uploadBy || "-" }}
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>

        <tr *matNoDataRow>
          <td class="mat-cell not-found" colspan="9999">
            <app-empty-record
              *ngIf="list.length === 0"
              [disableSubTitle]="true"
            >
            </app-empty-record>
          </td>
        </tr>
      </table>
    </div>

    <mat-divider></mat-divider>

    <mat-paginator
      [pageSizeOptions]="[50, 100, 150]"
      [pageSize]="limit"
      [length]="length"
      (page)="pageChange($event)"
      aria-label="Select page of users"
    ></mat-paginator>
  </div>
</div>
