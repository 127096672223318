import { NavMenuInterface } from "../interface/common.interface";

//*** NOTE
//Order of the array will determine the tab order shown in the Navigation bar

export const NAV_MENU_LIST: NavMenuInterface[] = [
  {
    levelOneId: "p_motor",
    tabUrl: "motor",
    tabTitle: "Car Insurance",
    tabIcon: "directions_car",
    levelTwo: [
      {
        url: "/motor/quotations",
        icon: "request_quote",
        title: "Quotation",
        levelTwoId: "s_motor_quotation",
      },
      {
        url: "/motor/iq-quotation",
        icon: "request_quote",
        title: "Instant Quotation",
        levelTwoId: "s_motor_iqquotation",
      },
      {
        url: "/motor/recent-quote-links",
        icon: "link",
        title: "Active Quote",
        levelTwoId: "s_motor_activequote",
      },
      {
        url: "/motor/issuances",
        icon: "spellcheck",
        title: "Issuance",
        levelTwoId: "s_motor_issuance",
      },
      {
        url: "/motor/issuance-issues",
        icon: "warning",
        title: "Issuance Issue",
        levelTwoId: "s_motor_issuanceissues",
      },
      {
        url: "/motor/roadtax",
        icon: "local_shipping",
        title: "Roadtax",
        levelTwoId: "s_motor_roadtax",
      },
      {
        url: "/motor/policies",
        icon: "policy",
        title: "Policies",
        levelTwoId: "s_motor_policy",
      },
      {
        url: "/motor/bulk-quotation",
        icon: "storage",
        title: "Bulk quotation",
        levelTwoId: "s_motor_bulkquotation",
      },
      {
        url: "/motor/endorsement",
        icon: "assignment",
        title: "Endorsement",
        levelTwoId: "s_motor_endorsement",
      },
      {
        url: "/motor/early-quotation",
        icon: "access_time",
        title: "Too early renewals",
        levelTwoId: "s_motor_tooearly",
      },
      {
        url: "/motor/banner-upload",
        icon: "add_photo_alternate",
        title: "Banner Upload",
        levelTwoId: null, // NOTE_CHECK: leave this null and get the leveltwoId from backend later when API is completed
      },
    ],
  },
  {
    levelOneId: "p_digitalrep",
    tabUrl: "dealer",
    tabTitle: "Digital Rep",
    tabIcon: "add_business",
    levelTwo: [
      {
        url: "/dealer/quotations",
        icon: "request_quote",
        title: "Quotation",
        levelTwoId: "s_digitalrep_quotation",
      },
      {
        url: "/dealer/recent-quote-links",
        icon: "link",
        title: "Active Quote",
        levelTwoId: "s_digitalrep_activequote",
      },
      {
        url: "/dealer/issuances",
        icon: "spellcheck",
        title: "Issuance",
        levelTwoId: "s_digitalrep_issuance",
      },
      {
        url: "/dealer/issuance-issues",
        icon: "warning",
        title: "Issuance Issue",
        levelTwoId: "s_digitalrep_issuanceissues",
      },
      {
        url: "/dealer/policies",
        icon: "policy",
        title: "Policies",
        levelTwoId: "s_digitalrep_policy",
      },
      {
        url: "/dealer/roadtax",
        icon: "local_shipping",
        title: "Roadtax",
        levelTwoId: "s_digitalrep_roadtax",
      },
    ],
  },
  {
    levelOneId: "p_claims",
    tabUrl: "claims",
    tabTitle: "Claims",
    tabIcon: "monetization_on",
    levelTwo: [
      {
        url: "/claims/rider/foodpanda",
        icon: "sports_motorsports",
        title: "Rider - FoodPanda",
        levelTwoId: "s_claims_riderprotectionfp",
      },
      {
        url: "/claims/rider/shopeefood",
        icon: "sports_motorsports",
        title: "Rider - ShopeeFood",
        levelTwoId: "s_claims_riderprotectionsf",
      },
      {
        url: "/claims/rider/spxexpress",
        icon: "sports_motorsports",
        title: "Rider - ShopeeXpress",
        // TODO: levelTwoId should be "s_claims_riderprotectionsx" but due to access token size limitations, temporary set to shopeefood id
        levelTwoId: "s_claims_riderprotectionsf",
      },
      {
        url: "/claims/ecommerce",
        icon: "shield",
        title: "Damage Protection",
        levelTwoId: "s_claims_damageprotection",
      },
      {
        url: "/claims/extended-warranty",
        icon: "monitor_heart",
        title: "EWEP - AIA",
        levelTwoId: "s_claims_extendedwarranty",
      },
      {
        url: "/claims/extended-warranty-bs",
        icon: "monitor_heart",
        title: "EWEP - Sompo",
        levelTwoId: "s_claims_extendedwarranty",
      },
    ],
  },
  {
    levelOneId: "p_damageprotection",
    tabUrl: "damageprotection",
    tabTitle: "Damage Protection",
    tabIcon: "shield",
    levelTwo: [
      {
        url: "/damageprotection/shopee",
        icon: "table_chart",
        title: "Shopee",
        levelTwoId: "s_damageprotection_shopee",
      },
    ],
  },
  {
    levelOneId: "p_extendedwarranty",
    tabUrl: "extendedwarranty",
    tabTitle: "Extended Warranty",
    tabIcon: "monitor_heart",
    levelTwo: [
      {
        url: "/extendedwarranty/shopee",
        icon: "table_chart",
        title: "Shopee - AIA",
        levelTwoId: "s_extendedwarranty_shopee",
      },
      {
        url: "/extendedwarranty/lulu",
        icon: "table_chart",
        title: "LuLu",
        levelTwoId: null,
      },
      {
        url: "/extendedwarranty/sompo",
        icon: "table_chart",
        title: "Shopee - Sompo",
        levelTwoId: "s_extendedwarranty_shopee",
      },
    ],
  },
  {
    levelOneId: "p_riderprotection",
    tabUrl: "rideraccident",
    tabTitle: "Rider Accident",
    tabIcon: "sports_motorsports",
    levelTwo: [
      {
        url: "/rideraccident/foodpanda",
        icon: "table_chart",
        title: "FoodPanda",
        levelTwoId: "s_riderprotection_fp",
      },
      {
        url: "/rideraccident/shopeefood",
        icon: "table_chart",
        title: "ShopeeFood",
        levelTwoId: "s_riderprotection_sf",
      },
      {
        url: "/rideraccident/spxexpress",
        icon: "table_chart",
        title: "ShopeeXpress",
        // TODO: levelTwoId should be "s_riderprotection_sx" but due to access token size limitations, temporary set to shopeefood id
        levelTwoId: "s_riderprotection_sf",
      },
    ],
  },
  {
    levelOneId: "p_employeebenefits",
    tabUrl: "employeebenefits",
    tabTitle: "Employee Benefits",
    tabIcon: "business",
    levelTwo: [
      {
        url: "/employeebenefits/main",
        icon: "table_chart",
        title: "Clients",
        levelTwoId: "s_employeebenefits_clients",
      },
    ],
  },
  {
    levelOneId: "p_externalaccounts",
    tabUrl: "accounts",
    tabTitle: "External User Accounts",
    tabIcon: "person",
    levelTwo: [
      {
        url: "/accounts/riders/foodpanda",
        icon: "sports_motorsports",
        title: "FoodPanda",
        levelTwoId: "s_externalaccounts_fp",
      },
      {
        url: "/accounts/riders/shopeefood",
        icon: "sports_motorsports",
        title: "ShopeeFood",
        levelTwoId: "s_externalaccounts_sf",
      },
      {
        url: "/accounts/ecommerce",
        icon: "local_mall",
        title: "Shopee",
        levelTwoId: "s_externalaccounts_shopee",
      },
      {
        url: "/accounts/motor",
        icon: "directions_car",
        title: "Motor Dashboard",
        levelTwoId: "s_externalaccounts_motorpartner",
      },
      {
        url: "/accounts/generate-coc",
        icon: "description",
        title: "Confirmation of Cover",
        levelTwoId: "s_externalaccounts_generatecoc",
      },
    ],
  },
  {
    levelOneId: "p_report",
    tabUrl: "reports",
    tabTitle: "Reports",
    tabIcon: "equalizer",
    levelTwo: [
      {
        url: "/report/ops-productivity",
        icon: "equalizer",
        title: "OPS Productivity Report",
        levelTwoId: "s_report_ops",
      },
      {
        url: "/report/sla-report",
        icon: "equalizer",
        title: "SLA Report",
        levelTwoId: "s_report_sla",
      },
    ],
  },
  {
    levelOneId: null,
    tabUrl: "promo-code",
    tabTitle: "Promo Code Generation",
    tabIcon: "confirmation_number",
    levelTwo: [
      {
        url: "/promo-code/motor",
        icon: "equalizer",
        title: "B2B / B2C / B2B2C",
        levelTwoId: null,
      },
      {
        url: "/promo-code/dealer",
        icon: "equalizer",
        title: "Digital Rep",
        levelTwoId: null,
      },
    ],
  },
  {
    levelOneId: null, //null means every user would have access to this page
    tabUrl: "settings",
    tabTitle: "User settings",
    tabIcon: "settings",
    levelTwo: [
      {
        url: "/settings/users",
        icon: "people",
        title: "User access",
        levelTwoId: "s_settings_useraccess",
      },
      {
        url: "/settings/myaccount",
        icon: "person",
        title: "My account",
        levelTwoId: null,
      },
      {
        url: "/settings/roles",
        icon: "person",
        title: "Role",
        levelTwoId: "s_settings_role",
      },
    ],
  },
];
