import { environment } from "src/environments/environment";

const baseUrl = `${environment.url}/api/Dealer/`;

export const ENDPOINTS = {
	dealer: {
		quotation: {
			getListing: `${baseUrl}DealerQuotation/GetListing`,
			getDetailById: `${baseUrl}DealerQuotation/GetDetailById/`,
			getYesterdayCount: `${baseUrl}DealerQuotation/GetYesterdayCount`,
			getVehicleInfo: `${baseUrl}DealerQuotation/GetVehicleInfo`,
			getChangeOwnershipDetailById: `${baseUrl}DealerQuotation/GetChangeOwnershipDetail/`,
			getClonedQuotationValueById: `${baseUrl}DealerQuotation/GetClonedQuotationValue/`,
			getQuotationPolicyById: `${baseUrl}DealerQuotation/GetQuotationPolicyById/`,
			getPaAndVehicleInfoById: `${baseUrl}DealerQuotation/GetPAndOVehicleInfo`,
			add: `${baseUrl}DealerQuotation/Add`,
			approve: `${baseUrl}DealerQuotation/Approve/`,
			reject: `${baseUrl}DealerQuotation/Reject/`,
			editVehicle: `${baseUrl}DealerQuotation/EditVehicle/`,
			updateQuotationNcd: `${baseUrl}DealerQuotation/UpdateQuotationNCD/`,
			editCustomerById: `${baseUrl}DealerQuotation/EditCustomer/`,
			getDealerCategoriesSelection: `${baseUrl}DealerQuotation/GetDealerCategoriesSelecion/`,
		},
		activeQuote: {
			getListing: `${baseUrl}DealerLink/GetListing`,
			updateIsSend: `${baseUrl}DealerLink/UpdateIsSend/`,
		},
		issuance: {
			getListing: `${baseUrl}DealerInsurance/GetListing`,
			getDetailById: `${baseUrl}DealerInsurance/GetDetailById/`,
			getYesterdayCount: `${baseUrl}DealerInsurance/GetYesterdayCount`,
			getEwpDetailsByPolicyId: `${baseUrl}DealerInsurance/GetEwpDetailsByPolicyId/`,
			getTopUpByPolicyId: `${baseUrl}DealerInsurance/GetTopUpByPolicyId/`,
			getIssueByPolicyId: `${baseUrl}DealerInsurance/GetIssueByPolicyId/`,
			getCovernoteByPolicyId: `${baseUrl}DealerInsurance/GetCovernoteByPolicyId/`,
			updateIssuebyId: `${baseUrl}DealerInsurance/UpdateIssue/`,
			sendIssueEmail: `${baseUrl}DealerInsurance/SendIssueEmail/`,
			updateNCDbyId: `${baseUrl}DealerInsurance/UpdateNCD/`,
			UpdateIssuebyId: `${baseUrl}DealerInsurance/UpdateIssue/`,
			addPolicyTopUp: `${baseUrl}DealerInsurance/AddPolicyTopUp/`,
			uploadCovernote: `${baseUrl}DealerInsurance/UploadCovernote/`,
			updateEwpStatus: `${baseUrl}DealerInsurance/UpdateEwpStatus/`,
		},
		issuanceIssue: {
			getListing: `${baseUrl}DealerIssuanceIssue/GetListing`,
		},
	},
};
