<mat-toolbar class="page-header">
	<div class="menu-container">
		<div class="pg-title">Dashboard</div>

		<span fxFlex></span>
	</div>
</mat-toolbar>

<div class="content-container">
	<mat-card class="card-details">
		<mat-card-header>
			<mat-card-title>Page Header</mat-card-title>
		</mat-card-header>
		<mat-divider></mat-divider>
		<div class="content-wrapper">
			<div>
				Listing Header
				<pre>
          &lt;mat-toolbar class="page-header"&gt;
              &lt;div class="menu-container"&gt;
        
                &lt;div class="pg-title"&gt;
                Dashboard
                &lt;/div"&gt;
          
                &lt;span fxFlex&gt; &lt;/span&gt;
              &lt;/div&gt;
           &lt;/mat-toolbar&gt;
        </pre>
			</div>

			<div>
				Page Header With Input
				<pre>
            &lt;mat-toolbar class="page-header"&gt;
                &lt;div class="menu-container"&gt;
                  &lt;button mat-icon-button class="pg-header-btn"&gt;
                    &lt;mat-icon&gt;chevron_left &lt;/mat-icon&gt;
                  &lt;/button"&gt;
            
                  &lt;div class="pg-title"&gt;
                  Dashboard
                  &lt;/div"&gt;
            
                  &lt;span fxFlex&gt; &lt;/span&gt;
                  &lt;mat-form-field appearance="outline" floatLabel="never" class="pg-filter"&gt;
                  &lt;input matInput placeholder="Search here..." autocomplete="off"&gt;
                  &lt;button mat-icon-button matSuffix class="clear-field-btn"&gt;
                      &lt;mat-icon&gt;close &lt;/mat-icon&gt;
                      &lt;/button&gt;
              
                   &lt;/mat-form-field&gt;
                   &lt;mat-form-field appearance="outline" floatLabel="never" class="pg-filter"&gt;
                     &lt;mat-select multiple&gt;
                     &lt;mat-select-trigger&gt;
                        Trigger
                       &lt;/mat-select-trigger&gt;
              
                       &lt;mat-option&gt;
                        Option &lt;/mat-option&gt;
                     &lt;/mat-select&gt;
                     &lt;button mat-icon-button matSuffix class="clear-field-btn"&gt;
                       &lt;mat-icon&gt;close &lt;/mat-icon&gt;
                     &lt;/button&gt;
              
                     &lt;/mat-form-field&gt;
                &lt;/div&gt;
             &lt;/mat-toolbar&gt;
          </pre
				>
			</div>
			<div>
				Detail/Form Header
				<pre>
            &lt;mat-toolbar class="page-header"&gt;
                &lt;div class="menu-container"&gt;
                  &lt;button mat-icon-button class="pg-header-btn"&gt;
                    &lt;mat-icon&gt;chevron_left &lt;/mat-icon&gt;
                  &lt;/button"&gt;
            
                  &lt;div class="pg-title"&gt;
                  Dashboard
                  &lt;/div"&gt;
            
                  &lt;span fxFlex&gt; &lt;/span&gt;
                &lt;/div&gt;
             &lt;/mat-toolbar&gt;
          </pre
				>
			</div>
		</div>
	</mat-card>

	<mat-card class="card-details">
		<mat-card-header>
			<mat-card-title>Table</mat-card-title>
		</mat-card-header>
		<mat-divider></mat-divider>
		<div class="content-wrapper">
			<div>
				<pre>
      &lt;mat-card class="card-table"&gt;

      &lt;table mat-table [dataSource]="dataSource"&gt;
  
          &lt;ng-container matColumnDef="name"&gt;
              &lt;th mat-header-cell *matHeaderCellDef &gt; Name &lt;/th&gt;
              &lt;td mat-cell *matCellDef="let element"&gt; &lt;/td&gt;
          &lt;/ng-container&gt;
  
          &lt;ng-container matColumnDef="email"&gt;
              &lt;th mat-header-cell *matHeaderCellDef &gt; Email &lt;/th&gt;
              &lt;td mat-cell *matCellDef="let element"&gt;&lt;/td&gt;
          &lt;/ng-container&gt;
  
          &lt;ng-container matColumnDef="status"&gt;
              &lt;th mat-header-cell *matHeaderCellDef &gt; Status &lt;/th&gt;
              &lt;td mat-cell *matCellDef="let element"&gt; &lt;span class="cover-label"&gt;Active&lt;/span&gt; &lt;span class="cover-label grey"&gt;Inactive&lt;/span&gt; &lt;/td&gt;
          &lt;/ng-container&gt;
  
         
  
          &lt;ng-container matColumnDef="action"&gt;
              &lt;th mat-header-cell *matHeaderCellDef&gt; &lt;/th&gt;
              &lt;td mat-cell *matCellDef="let element"&gt; &lt;button mat-icon-button [matMenuTriggerFor]="menu"&gt;
                      &lt;mat-icon&gt;more_vert&lt;/mat-icon&gt;
                  &lt;/button&gt; &lt;/td&gt;
          &lt;/ng-container&gt;
  
          &lt;tr mat-header-row *matHeaderRowDef="displayedColumns"&gt;&lt;/tr&gt;
          &lt;tr mat-row *matRowDef="let row; columns: displayedColumns;"&gt;&lt;/tr&gt;
  
  
  
           &lt;/table&gt;
  
          &lt;mat-divider&gt;&lt;/mat-divider&gt;
  
          &lt;mat-paginator [pageSizeOptions]="[50, 100, 150]" 
          aria-label="Select page of users"&gt;&lt;/mat-paginator&gt;
        &lt;/mat-card&gt;
    
     </pre
				>
			</div>
		</div>
	</mat-card>

	<mat-card class="card-details">
		<mat-card-header>
			<mat-card-title>Page Container</mat-card-title>
		</mat-card-header>
		<mat-divider></mat-divider>
		<div class="content-wrapper">
			<div>
				div.content-container
				<div class="xsDesc-f">
					Default with max width 2000px and 16px of padding, mostly use in
					listing & details page
				</div>
			</div>

			<div>
				.md

				<div class="xsDesc-f">
					Max width 1200px and 16px of padding, mostly use in create page
				</div>
			</div>

			<div>
				.small

				<div class="xsDesc-f">
					Max width 1000px and 16px of padding, mostly use in create page
				</div>
			</div>
			<div>
				.xx-small
				<div class="xsDesc-f">
					Max width 500px and 16px of padding, mostly use in single profile page
				</div>
			</div>
			<div>.with-search</div>
			<div>.with-float</div>
		</div>
	</mat-card>

	<mat-card class="card-details">
		<mat-card-header>
			<mat-card-title>Fonts</mat-card-title>
		</mat-card-header>
		<mat-divider></mat-divider>
		<div class="content-wrapper">
			<div>
				<div fxLayout="row" fxLayoutAlign=" center">
					<div fxFlex>
						.pg-title
						<div class="xsDesc-f">font: 20px 700</div>
					</div>
					<div class="pg-title" fxFlex>Example</div>
				</div>
			</div>
			<div>
				<div fxLayout="row" fxLayoutAlign=" center">
					<div fxFlex>
						.details-title
						<div class="xsDesc-f">font: 24px 700</div>
					</div>
					<div class="details-title" fxFlex>Example</div>
				</div>
			</div>
			<div>
				<div fxLayout="row" fxLayoutAlign=" center">
					<div fxFlex>
						.details-desc
						<div class="xsDesc-f">font: 16px 500</div>
					</div>
					<div class="details-desc" fxFlex>Example</div>
				</div>
			</div>
			<div>
				<div fxLayout="row" fxLayoutAlign=" center">
					<div fxFlex>
						.tb-label
						<div class="xsDesc-f">font: 16px 500</div>
					</div>
					<div class="tb-label" fxFlex>Example</div>
				</div>
			</div>
			<div>
				<div fxLayout="row" fxLayoutAlign=" center">
					<div fxFlex>
						.tb-desc
						<div class="xsDesc-f">font: 16px 500</div>
					</div>
					<div class="tb-desc" fxFlex>Example</div>
				</div>
			</div>
			<div>
				<div fxLayout="row" fxLayoutAlign=" center">
					<div fxFlex>
						.title-f
						<div class="xsDesc-f">font: 20px 700</div>
					</div>
					<div class="title-f" fxFlex>Example</div>
				</div>
			</div>
			<div>
				<div fxLayout="row" fxLayoutAlign=" center">
					<div fxFlex>
						.value-f
						<div class="xsDesc-f">font: 16px 600</div>
					</div>
					<div class="value-f" fxFlex>Example</div>
				</div>
			</div>
			<div>
				<div fxLayout="row" fxLayoutAlign=" center">
					<div fxFlex>
						.label-f
						<div class="xsDesc-f">font: 16px 500</div>
					</div>
					<div class="value-f" fxFlex>Example</div>
				</div>
			</div>
			<div>
				<div fxLayout="row" fxLayoutAlign=" center">
					<div fxFlex>
						.desc-f
						<div class="xsDesc-f">font: 14px 500</div>
					</div>
					<div class="desc-f" fxFlex>Example</div>
				</div>
			</div>
		</div>
	</mat-card>

	<mat-card class="card-details">
		<mat-card-header>
			<mat-card-title>Colors</mat-card-title>
		</mat-card-header>
		<mat-divider></mat-divider>
		<div class="content-wrapper">
			<div>
				<div fxFlex>.color-primary</div>
				<div fxFlex class="color-primary mdTitle-f">Example</div>
			</div>
			<div>
				<div fxFlex>.color-secodary</div>
				<div fxFlex class="color-secodary mdTitle-f">Example</div>
			</div>
			<div>
				<div fxFlex>.color-sub-text</div>
				<div fxFlex class="color-sub-text mdTitle-f">Example</div>
			</div>
			<div>
				<div fxFlex>.color-rev-sub-text</div>
				<div fxFlex class="color-rev-sub-text mdTitle-f">Example</div>
			</div>
			<div>
				<div fxFlex>.color-orange</div>
				<div fxFlex class="color-orange mdTitle-f">Example</div>
			</div>
			<div>
				<div fxFlex>.color-red</div>
				<div fxFlex class="color-red mdTitle-f">Example</div>
			</div>
			<div>
				<div fxFlex>.color-green</div>
				<div fxFlex class="color-green mdTitle-f">Example</div>
			</div>
			<div>
				<div fxFlex>.color-teal</div>
				<div fxFlex class="color-teal mdTitle-f">Example</div>
			</div>
			<div>
				<div fxFlex>.color-blue</div>
				<div fxFlex class="color-blue mdTitle-f">Example</div>
			</div>
			<div>
				<div fxFlex>.color-white</div>
				<div fxFlex class="color-white mdTitle-f">Example</div>
			</div>
			<div>
				<div fxFlex>.color-light-white</div>
				<div fxFlex class="color-light-white mdTitle-f">Example</div>
			</div>
			<div>
				<div fxFlex>.color-black</div>
				<div fxFlex class="color-black mdTitle-f">Example</div>
			</div>
			<div>
				<div fxFlex>.color-white</div>
				<div fxFlex class="color-white mdTitle-f">Example</div>
			</div>
		</div>
	</mat-card>

	<mat-card class="card-details">
		<mat-card-header>
			<mat-card-title>Cover Label</mat-card-title>
		</mat-card-header>
		<mat-divider></mat-divider>
		<div class="content-wrapper">
			<div fxLayoutAlign=" center">
				<div fxFlex>Default</div>
				<div fxFlex>
					<div class="cover-label">Example</div>
				</div>
			</div>
			<div fxLayoutAlign=" center">
				<div fxFlex>.red</div>
				<div fxFlex>
					<div class="cover-label red">Example</div>
				</div>
			</div>
			<div fxLayoutAlign=" center">
				<div fxFlex>.orange</div>
				<div fxFlex>
					<div class="cover-label orange">Example</div>
				</div>
			</div>
			<div fxLayoutAlign=" center">
				<div fxFlex>.green</div>
				<div fxFlex>
					<div class="cover-label green">Example</div>
				</div>
			</div>
			<div fxLayoutAlign=" center">
				<div fxFlex>.teal</div>
				<div fxFlex>
					<div class="cover-label teal">Example</div>
				</div>
			</div>
			<div fxLayoutAlign=" center">
				<div fxFlex>.grey</div>
				<div fxFlex>
					<div class="cover-label grey">Example</div>
				</div>
			</div>
		</div>
	</mat-card>

	<mat-card class="card-details">
		<mat-card-header>
			<mat-card-title>Icon Text</mat-card-title>
		</mat-card-header>
		<mat-divider></mat-divider>
		<div class="content-wrapper">
			<div>div.icon-text-wrapper>div.icon-text>mat-icon+span</div>
			<div>
				<div class="icon-text-wrapper">
					<span class="icon-text">
						<mat-icon>call</mat-icon><span>example</span>
					</span>
					<span class="icon-text">
						<mat-icon>mail</mat-icon><span>example</span>
					</span>
				</div>
			</div>
			<div></div>
		</div>
	</mat-card>

	<mat-card class="card-details">
		<mat-card-header>
			<mat-card-title>Card Details</mat-card-title>
		</mat-card-header>
		<mat-divider></mat-divider>
		<div class="content-wrapper">
			<div>
				mat-card.card-details>(div.card-title-container>div.title-f)+mat-divider+div.row-content>div>(span.label-f+span.value-f)
			</div>
		</div>
	</mat-card>

	<mat-card class="card-details">
		<mat-card-header>
			<mat-card-title>Form Field</mat-card-title>
		</mat-card-header>
		<mat-divider></mat-divider>
		<div class="content-wrapper">
			<div>
				mat-card.form-field>(div.card-title-container>div.title-f)+mat-divider+mat-card-content
			</div>
			<div class="xsTitle-f">Form Field Classes</div>
			<div>.left</div>
			<div>.right</div>
			<div>.mobile-full</div>
		</div>
	</mat-card>
</div>
