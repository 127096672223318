import { Injectable } from "@angular/core";
import { Observable, throwError } from "rxjs";
import {
  ApproveQuotationPolicyPayload,
  ChangeOwnershipDetails,
  ClonedQuotation,
  CustomerInfo,
  DealerCloneQuotePayload,
  DealerQuotationDetails,
  DealerQuotationFilterQuery,
  DealerQuotationResponse,
  QuotationPolicy,
  VehicleInfo,
  VehicleInfoResponse,
} from "src/app/share/interface/dealer/quotation.interface";
import { ENDPOINTS } from "../constants/endpoints";
import { HttpClient } from "@angular/common/http";
import { catchError, shareReplay } from "rxjs/operators";
import { AlertService } from "src/app/share/service/alert.service";

@Injectable({
  providedIn: "root",
})
export class DealerQuotationService {
  constructor(private http: HttpClient, private alertService: AlertService) {}

  getQuotationList(
    payload: DealerQuotationFilterQuery,
  ): Observable<DealerQuotationResponse> {
    return this.http
      .post<DealerQuotationResponse>(
        ENDPOINTS.dealer.quotation.getListing,
        payload,
      )
      .pipe(
        shareReplay(),
        catchError((err) => {
          const message = "Failed to get the data";
          console.error(message, err);
          this.alertService.openSnackBar(message);
          return throwError(err);
        }),
      );
  }

  getYesterdayCount(): Observable<number> {
    return this.http
      .get<number>(ENDPOINTS.dealer.quotation.getYesterdayCount)
      .pipe(
        catchError((err) => {
          const message = "Failed to get the data";
          console.error(message, err);
          this.alertService.openSnackBar(message);
          return throwError(err);
        }),
      );
  }

  getQuotationDetailById(id: string): Observable<DealerQuotationDetails> {
    return this.http
      .get<DealerQuotationDetails>(
        ENDPOINTS.dealer.quotation.getDetailById + id,
      )
      .pipe(
        catchError((err) => {
          const message = "Failed to get the data";
          console.error(message, err);
          this.alertService.openSnackBar(message);
          return throwError(err);
        }),
      );
  }

  getVehicleInfo(payload: {
    vehicleNo: string;
    identityNo: string;
  }): Observable<VehicleInfoResponse> {
    return this.http
      .post<VehicleInfoResponse>(
        ENDPOINTS.dealer.quotation.getVehicleInfo,
        payload,
      )
      .pipe(
        catchError((err) => {
          const message = "Failed to get vehicle info";
          console.error(message, err);
          this.alertService.openSnackBar(message);
          return throwError(err);
        }),
      );
  }

  addQuote(payload: DealerCloneQuotePayload): Observable<any> {
    return this.http.post(ENDPOINTS.dealer.quotation.add, payload).pipe(
      catchError((err) => {
        const message = "Failed to clone quote";
        console.error(message, err);
        this.alertService.openSnackBar(message);
        return throwError(err);
      }),
    );
  }

  getClonedQuotationById(id: string): Observable<ClonedQuotation | null> {
    return this.http
      .get<ClonedQuotation | null>(
        ENDPOINTS.dealer.quotation.getClonedQuotationValueById + id,
      )
      .pipe(
        catchError((err) => {
          const message = "Failed to get cloned quote data";
          console.error(message, err);
          this.alertService.openSnackBar(message);
          return throwError(err);
        }),
      );
  }

  getChangeOwnershipDetailById(id: string): Observable<ChangeOwnershipDetails> {
    return this.http
      .get<ChangeOwnershipDetails>(
        ENDPOINTS.dealer.quotation.getChangeOwnershipDetailById + id,
      )
      .pipe(
        catchError((err) => {
          const message = "Failed to get the data";
          console.error(message, err);
          this.alertService.openSnackBar(message);
          return throwError(err);
        }),
      );
  }

  updateVehicleInfo(id: string, payload: VehicleInfo): Observable<any> {
    return this.http
      .post<any>(ENDPOINTS.dealer.quotation.editVehicle + id, payload)
      .pipe
      // catchError((err) => {
      // 	const message = "Failed to edit vahicle";
      // 	console.error(message, err);
      // 	this.alertService.openSnackBar(message);
      // 	return throwError(err);
      // }),
      ();
  }

  updateCustomerInfo(id: string, payload: CustomerInfo): Observable<any> {
    return this.http
      .post<any>(ENDPOINTS.dealer.quotation.editCustomerById + id, payload)
      .pipe(
        catchError((err) => {
          const message = "Failed to edit customer";
          console.error(message, err);
          this.alertService.openSnackBar(message);
          return throwError(err);
        }),
      );
  }

  getQuotationPolicyById(id: string): Observable<QuotationPolicy> {
    return this.http
      .get<QuotationPolicy>(
        ENDPOINTS.dealer.quotation.getQuotationPolicyById + id,
      )
      .pipe(
        catchError((err) => {
          const message = "Failed to load the data";
          console.error(message, err);
          this.alertService.openSnackBar(message);
          return throwError(err);
        }),
      );
  }

  approveUpdateQuotationPolicy(
    id: string,
    payload: ApproveQuotationPolicyPayload,
  ): Observable<any> {
    return this.http
      .post<any>(ENDPOINTS.dealer.quotation.approve + id, payload)
      .pipe
      // catchError((err) => {
      // 	const message = "Failed to update";
      // 	console.error(message, err);
      // 	this.alertService.openSnackBar(message);
      // 	return throwError(err);
      // }),
      ();
  }

  rejectQuote(
    id: string,
    payload: {
      rejectReasonId: string;
      rejectRemark: string;
      comment: string | null;
    },
  ): Observable<any> {
    return this.http
      .post<any>(ENDPOINTS.dealer.quotation.reject + id, payload)
      .pipe
      // catchError((err) => {
      // 	const message = "Failed to reject";
      // 	console.error(message, err);
      // 	this.alertService.openSnackBar(message);
      // 	return throwError(err);
      // }),
      ();
  }

  updateQuotationNcd(
    id: string,
    payload: {
      expiredDate: string;
      effectiveDate: string;
      typeOfVehicle: string;
      ncd: number;
    },
  ): Observable<any> {
    return this.http
      .post<any>(ENDPOINTS.dealer.quotation.updateQuotationNcd + id, payload)
      .pipe(
        catchError((err) => {
          const message = "Failed to update";
          console.error(message, err);
          this.alertService.openSnackBar(message);
          return throwError(err);
        }),
      );
  }

  getDealerSelection(): Observable<{ name: string }[]> {
    return this.http
      .get<{ name: string }[]>(
        ENDPOINTS.dealer.quotation.getDealerCategoriesSelection,
      )
      .pipe(
        shareReplay(),
        catchError((err) => {
          const message = "Failed to get the data";
          console.error(message, err);
          this.alertService.openSnackBar(message);
          return throwError(err);
        }),
      );
  }
}
