import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { EmptyRecordComponent } from "./empty-record.component";

@NgModule({
	exports: [EmptyRecordComponent],
	declarations: [EmptyRecordComponent],
	imports: [CommonModule],
})
export class EmptyRecordModule {}
