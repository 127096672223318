import { Component, Inject, Output, EventEmitter } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";

export interface ConfirmationDialogModel {
	title: string;
	content: string;
	boldContent?: string;
	nextContent?: string;
	hideCancelBtn?: boolean;
	cancelBtnText?: string;
	okBtnText?: string;
	btnColor?: string;
	hideQuestionMark?: boolean;
}

@Component({
	selector: "confirmation-dialog",
	templateUrl: "confirmation.dialog.html",
	styleUrls: ["confirmation.dialog.scss"],
})
export class ConfirmationDialog {
	@Output() confirm = new EventEmitter<any>();

	constructor(@Inject(MAT_DIALOG_DATA) public data: ConfirmationDialogModel) {}

	onConfirm() {
		this.confirm.emit(true);
	}
}
