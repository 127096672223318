import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../../../environments/environment";
import {
  PromoCodeListingInterface,
  UploadStatusListingInterface,
} from "../../_interfaces/promoCode.interface";

@Injectable({
  providedIn: "root",
})
export class PromoCodeMotorService {
  private basePath: string = `${environment.url}/api/motor/MotorPromoCode/`;

  constructor(private http: HttpClient) {}

  getListing(request: MotorPromoCodeListingRequest) {
    return this.http.post<MotorPromoCodeListingResponse>(
      `${this.basePath}GetListing`,
      request,
    );
  }

  getStatusList() {
    return this.http.get(`${this.basePath}GetPromoCodeStatusSelection`);
  }

  getBusinessTypeList() {
    return this.http.get(`${this.basePath}GetBusinessTypeSelection`);
  }

  downloadTemplate() {
    return this.http.get(`${this.basePath}DownloadPromoCodeExcelTemplate`, {
      responseType: "blob",
    });
  }

  deactivate(id: string) {
    return this.http.post(`${this.basePath}Deactivate/${id}`, null);
  }

  getUploadListing(columnIndex: number, sortDirection: string) {
    return this.http.post<UploadStatusListingInterface>(
      `${this.basePath}GetUploadStatusListing`,
      {
        columnIndex,
        sortDirection,
      },
    );
  }

  getPromoCodeExcel() {
    return this.http.post(`${this.basePath}UploadPromoCodeExcel`, null);
  }

  uploadExcel(file: any) {
    return this.http.post(`${this.basePath}UploadPromoCodeExcel`, file, {
      responseType: "blob",
    });
  }

  getListingExcel(request: MotorPromoCodeListingRequest) {
    return this.http.post(`${this.basePath}GetListingExcel`, request, {
      responseType: "blob",
    });
  }
}

export interface MotorPromoCodeListingRequest {
  limit: number;
  offset: number;
  text: string;
  columnIndex: number;
  sortDirection: string;
  creationStartDate: Date;
  creationEndDate: Date;
  effectiveStartDate: Date;
  effectiveEndDate: Date;
  statusList: string[];
  businessList: string[];
}

interface MotorPromoCodeListingResponse {
  count: number;
  data: PromoCodeListingInterface[];
}
