<div class="centered">
	<div class="card-content text-center">
		<h1>404</h1>
		<h3 class="text-uppercase">Page Not Found</h3>
		<p class="text-muted m-t-30 m-b-30">
			You seem to be trying to find this way to home
		</p>

		<a mat-raised-button color="primary" class="m-auto" [routerLink]="['/']"
			>Back to home</a
		>
	</div>
</div>
