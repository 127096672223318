import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { ErrorComponent } from "./error/error.component";
import { ForgotPasswordComponent } from "./forgot-password/forgot-password.component";
import { LoginComponent } from "./login/login.component";

const routes: Routes = [
	{
		path: "",
		children: [
			{
				path: "login",
				component: LoginComponent,
			},
			{
				path: "forgot-password",
				component: ForgotPasswordComponent,
			},
			{
				path: "404",
				component: ErrorComponent,
			},
		],
	},
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule],
})
export class AuthenticationRoutingModule {}
