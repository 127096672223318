<div class="pc-log-dialog">
	<div class="pc-log-dialog__title">
		<div mat-dialog-title>Status Tracker</div>
		<button
			mat-dialog-close
			class="pc-log-dialog__title__btn"
		>
			<mat-icon>close</mat-icon>
		</button>
	</div>
	<mat-divider></mat-divider>
	<div mat-dialog-content>
		<mat-card class="card-table">
			<table 
				mat-table 
				matSort
				[dataSource]="tableData$ | async"
				(matSortChange)="sortChange($event)"
				matSortActive="date_uploaded"
				matSortDisableClear
				matSortDirection="desc"
			>
				<ng-container matColumnDef="date_uploaded">
					<th mat-header-cell *matHeaderCellDef mat-sort-header>Date Uploaded</th>
					<td
						mat-cell
						*matCellDef="let element"
					>
						{{ element?.createdDate | date: 'dd MMM yyyy hh:mm aa' }}
					</td>
				</ng-container>
				<ng-container matColumnDef="file_name">
					<th mat-header-cell *matHeaderCellDef>
						File Name
					</th>
					<td
						mat-cell
						*matCellDef="let element"
					>
						<span (click)="downloadFile(element)" class="link-text">
							{{ element?.fileName }}
						</span>
					</td>
				</ng-container>
				<ng-container matColumnDef="status">
					<th mat-header-cell *matHeaderCellDef>
						Status
					</th>
					<td mat-cell *matCellDef="let element">
						<span
							class="cover-label green"
							*ngIf="element?.isSuccess"
							>Success</span
						>
						<span
							class="cover-label red"
							*ngIf="!element?.isSuccess"
							>Failed</span
						>
					</td>
				</ng-container>
				<ng-container matColumnDef="uploaded_by">
					<th mat-header-cell *matHeaderCellDef>
						Uploaded By
					</th>
					<td
						mat-cell
						*matCellDef="let element"
					>
						{{ element?.createdBy }}
					</td>
				</ng-container>

				<tr
					mat-header-row
					*matHeaderRowDef="cancellationLogColumns"
				></tr>
				<tr
					mat-row
					*matRowDef="let row; columns: cancellationLogColumns"
				></tr>
			</table>
			<mat-paginator
				[pageSizeOptions]="[5, 10, 15]"
				[pageSize]="limit"
				[length]="length$ | async"
				(page)="pageChange($event)"
				aria-label="Select page of users"
			></mat-paginator>
		</mat-card>
	</div>
</div>
