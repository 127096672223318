<div class="login-page">
	<div class="login-form">
		<form [formGroup]="formGroup" (ngSubmit)="login()">
			<mat-card class="form-field">
				<img src="assets/image/logo.svg" class="login-logo" alt="" />
				<div class="sign-in-text">Sign In Now</div>
				<mat-card-content>
					<mat-form-field>
						<mat-label>Email</mat-label>
						<input
							matInput
							placeholder="Type here..."
							required
							formControlName="email"
							autocomplete="email"
						/>
						<mat-error>Email is required</mat-error>
					</mat-form-field>
					<mat-form-field>
						<mat-label>Password</mat-label>
						<input
							matInput
							[type]="hide ? 'password' : 'text'"
							placeholder="Type here..."
							formControlName="password"
							required
						/>
						<button
							type="button"
							mat-icon-button
							matSuffix
							(click)="hide = !hide"
							[attr.aria-label]="'Hide password'"
							[attr.aria-pressed]="hide"
						>
							<mat-icon>{{ hide ? "visibility_off" : "visibility" }}</mat-icon>
						</button>
						<mat-error>Password is required</mat-error>
					</mat-form-field>
					<div>
						<!-- <div
              class="forgot-password-link"
              routerLink="/authentication/forgot-password"
            >
              Forgot Password
            </div> -->
					</div>
					<button mat-raised-button color="primary" class="lg">Login</button>
				</mat-card-content>
			</mat-card>
		</form>
	</div>
	<div class="crm-info">
		<div>
			<div class="title">Welcome to Policystreet</div>
			<div class="desc">
				Turn your sales process into a state of the art, revenue-generating
				machine. Manage Your Pipeline with Total Visibility
			</div>
		</div>
	</div>
</div>
