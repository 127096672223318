export const triggerFileDownload = (data: Blob, fileName: string) => {
	const dataType = data.type;
	const binaryData = [data];
	const downloadLink = document.createElement("a");
	downloadLink.href = window.URL.createObjectURL(
		new Blob(binaryData, { type: dataType }),
	);
	downloadLink.setAttribute("download", fileName);
	document.body.appendChild(downloadLink);
	downloadLink.click();
	document.body.removeChild(downloadLink);
	window.URL.revokeObjectURL(downloadLink.href);
};
