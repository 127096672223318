import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";
import {
	UserListingInterface,
	UserProfileInterface,
} from "../interface/user.interface";

@Injectable({
	providedIn: "root",
})
export class UserDataService {
	private basePath: string = `${environment.url}/api/User/`;

	constructor(private http: HttpClient) {}

	login(email: string, password: string) {
		return this.http.get(
			`${this.basePath}Login?email=${email}&password=${encodeURIComponent(
				password,
			)}`,
			{ responseType: "text" },
		);
	}

	getUserAccessRights() {
		return this.http.get(`${this.basePath}GetUserAccessRights`);
	}

	getRole() {
		return this.http.get(`${this.basePath}GetRole`);
	}

	getProfile() {
		return this.http.get<UserProfileInterface>(`${this.basePath}GetProfile`);
	}

	getListing(
		limit: number,
		offset: number,
		text: string,
		columnIndex: number,
		sortDirection: string,
		status: boolean,
		psRoleIds: any,
	) {
		return this.http.post<{
			count: number;
			data: UserListingInterface[];
		}>(`${this.basePath}GetListing`, {
			limit,
			offset,
			text,
			columnIndex,
			sortDirection,
			status,
			psRoleIds,
		});
	}

	createUser(name: string, psRoleIds: any, email: string, password: string) {
		return this.http.post(`${this.basePath}Add`, {
			name,
			psRoleIds,
			email,
			password,
		});
	}

	editUser(id: string, name: string, psRoleIds: any) {
		return this.http.post(`${this.basePath}UpdateUserProfile/${id}`, {
			name,
			psRoleIds,
		});
	}

	toggleStatus(id: string) {
		return this.http.get(`${this.basePath}UpdateStatus/${id}`);
	}

	updatePassword(id: string, password: string) {
		return this.http.post(
			`${this.basePath}UpdateUserPassword/${id}?password=${encodeURIComponent(
				password,
			)}`,
			{},
		);
	}
}
