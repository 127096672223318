import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "app-skeleton-loader",
  templateUrl: "./skeleton-loader.component.html",
})
export class SkeletonLoaderComponent implements OnInit {
  @Input() count: number = 3;
  @Input() theme: any;

  themeOptions: any;

  constructor() {
    this.themeOptions = {
      height: "40px",
      "animation-duration": "1s",
    };
  }

  ngOnInit(): void {
    if (this.theme === undefined) return;
    this.themeOptions = this.theme;
  }
}
