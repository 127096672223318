import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import {
	EWPPoliciesListingInterface,
	EWPolicyClaimDetailInterface,
	EWPolicyClaimListingInterface
} from "../../interface/extended-warranty/shopee.interface";
import { environment } from "../../../../environments/environment";
import { CommonSelectionInterface } from "../../interface/common.interface";
import { Observable } from "rxjs";

@Injectable({
	providedIn: "root",
})
export class ShopeeEWPolicyDataService {
	private basePathPolicy: string = `${environment.url}/api/ShopeeEWPolicy/`;

	constructor(private http: HttpClient) {}

  /* To get Shopee Policy Listing */
	getListing(
		limit: number,
		offset: number,
		text: string,
		columnIndex: number,
		sortDirection: string,
		startDate: Date,
    	endDate: Date,
		insurer: string,
		claimStatusList?: string[],
		policyStatusList?: string[],
		groupPolicyNoList?: string[],
	) {
		return this.http.post<{
			count: number;
			data: EWPPoliciesListingInterface[];
		}>(`${this.basePathPolicy}GetListing`, {
			limit,
			offset,
			text,
			columnIndex,
			sortDirection,
			claimStatusList,
			policyStatusList,
			groupPolicyNoList,
			startDate,
			endDate,
			insurer
		});
	}

// 	getClaimTypeList(): CommonSelectionInterface[] {
// 		return [
// 			{
// 				id: "All Risks - Accidental Damage/Fire/Flood",
// 				name: "All Risks - Accidental Damage/Fire/Flood",
// 			},
// 			{ id: "Lightning Damage", name: "Lightning Damage" },
// 			{ id: "Loss due to Theft/Burglary", name: "Loss due to Theft/Burglary" },
// 			{ id: "Wear and Tear", name: "Wear and Tear" },
// 		];
// 	}

	getClaimStatusList(): CommonSelectionInterface[] {
		return [
			{ id: "New", name: "New", type: "cp, r&r" },
      { id: "Pending Insurer", name: "Pending Insurer", type: "cp" },
      { id: "Pending AMS", name: "Pending AMS", type: "r&r" },
			{ id: "Processing", name: "Processing" },
			{ id: "Approved", name: "Approved", type: "cp" },
			{ id: "Rejected", name: "Rejected", type: "cp, r&r" },
      { id: "Completed", name: "Completed", type: "cp, r&r" },
			{ id: "Disbursed", name: "Disbursed" },
		];
	}

	getPolicyStatusList(): CommonSelectionInterface[] {
		return [
			{ id: "Active", name: "Active" },
			{ id: "Cancelled", name: "Cancelled" },
			{ id: "Expired", name: "Expired" },
			{ id: "Claimed", name: "Claimed" },
		];
	}

	/* To cancel the EWP Policy based on ID */
	deactivatePolicy(id: string, values: any) {
		return this.http.post(
			`${this.basePathPolicy}DeactivateEWPolicy/${id}`,
			values,
		);
	}

	/* To get EWPolicy details */
	getPolicyClaimDetail(id: string) {
		return this.http.get<EWPolicyClaimDetailInterface>(
			`${this.basePathPolicy}GetEWPolicyClaimDetail/${id}`,
		);
	}

	/*To get EWP Policy claim listing */
	getPolicyClaimListing(
		id: string,
		limit: number,
		offset: number,
		text: string,
		columnIndex: number,
		sortDirection: string,
		claimStatusList: string[],
		// claimTypeList: string[],
	) {
		return this.http.post<{ count: number; data: EWPolicyClaimListingInterface[] }>(
			`${this.basePathPolicy}GetEWPolicyClaimListing/${id}`,
			{
				limit,
				offset,
				text,
				columnIndex,
				sortDirection,
				// claimTypeList,
				claimStatusList,
			},
		);
	}

  /* To export Shopee EWP policy listing into excel */
	getPolicyListingExcel(
		limit: number,
		offset: number,
		text: string,
		columnIndex: number,
		sortDirection: string,
		claimStatusList: string[],
		policyStatusList: string[],
		groupPolicyNoList: string[],
		startDate: Date,
		endDate: Date,
		insurer: string
	) {
		return this.http.post(
			`${this.basePathPolicy}GetPolicyListingExcel`,
			{
				limit,
				offset,
				text,
				columnIndex,
				sortDirection,
				claimStatusList,
				policyStatusList,
				groupPolicyNoList,
				startDate,
				endDate,
				insurer
			},
			{
				responseType: "blob",
			},
		);
	}

	
	editPhoneNo(id: string, phone: string){
		return this.http.post(`${this.basePathPolicy}EditPhoneNo/${id}`, {
			phone
		});
	}

	getPolicyBulkCancelLogListing(
		data: {
			limit: number,
			offset: number,
			text: string,
			columnIndex: number,
			sortDirection: string,
			isSuccess: boolean,
			startDate: Date,
			endDate: Date
		}
	) {
		return this.http.post<{ count: number; data: any[] }>(
			`${this.basePathPolicy}GetPolicyBulkCancelLogListing`,
			data,
		);
	}

	uploadPolicyBulkCancelExcel(data: File) {
		let formData: FormData = new FormData();
		formData.append('File', data); 

		return this.http.post(
			`${this.basePathPolicy}UploadPolicyBulkCancelExcel`,
			formData,
			{ responseType: "blob" },
		);
	}

	downloadPolicyBulkCancelExcelTemplate(): Observable<any> {
		return this.http.get<Blob>(
			`${this.basePathPolicy}DownloadPolicyBulkCancelExcelTemplate`,
			// @ts-ignore
			{ responseType: "blob" },
		);
	}


// 	// End Shopee Policy

// 	getClaimStatus() {
// 		return ["New", "Processing", "Approved", "Rejected", "Disbursed"];
// 	}

// 	// Shopee Document
// 	downloadById(id: string) {
// 		return this.http.get(`${this.basePathDocument}DownloadById/${id}`, {
// 			responseType: "blob",
// 		});
// 	}
// 	// End Shopee Document
}
