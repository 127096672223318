import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { AuthenticationRoutingModule } from "./authentication-routing.module";
import { LoginComponent } from "./login/login.component";
import { ErrorComponent } from "./error/error.component";
import { MaterialModule } from "../material-module";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { ForgotPasswordComponent } from "./forgot-password/forgot-password.component";

@NgModule({
	declarations: [LoginComponent, ErrorComponent, ForgotPasswordComponent],
	imports: [
		CommonModule,
		AuthenticationRoutingModule,
		FormsModule,
		ReactiveFormsModule,
		MaterialModule,
	],
})
export class AuthenticationModule {}
