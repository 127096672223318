import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { MaterialModule } from "./material-module";
import { AppBlankComponent } from "./layouts/blank/blank.component";
import { AuthenticationModule } from "./authentication/authentication.module";
import { DashboardModule } from "./dashboard/dashboard.module";
import { AppFullComponent } from "./layouts/full/full.component";
import { AppHeaderComponent } from "./layouts/header/header.component";
import { AppDrawerComponent } from "./layouts/drawer/drawer.component";
import { ConfirmationDialog } from "./share/dialog/confirmation-dialog/confirmation.dialog";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { MAT_DATE_LOCALE } from "@angular/material/core";

import { HttpRequestInterceptor } from "./share/service/http.service";
import { ServiceWorkerModule } from "@angular/service-worker";
import { environment } from "../environments/environment";
import { SingleTextInputDialog } from "./share/dialog/single-text-input-dialog/single-text-input-dialog";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { FlexLayoutModule } from "@angular/flex-layout";
import { AppDrawerDirective } from "./share/directive/app-drawer.directive";
import { NgxSpinnerModule } from "ngx-spinner";
import { NgxMaskModule } from "ngx-mask";
import { ClipboardModule } from "@angular/cdk/clipboard";
import { TimelineComponent } from "./share/component/timeline/timeline.component";
import { NgxMatSelectSearchModule } from "ngx-mat-select-search";
import {SharedComponentModule} from "./share/component/shared-component.module";
import { PolicyCancellationLogComponent } from './share/dialog/policy-cancellation-log/policy-cancellation-log.component';
import { PolicyCancellationUploadComponent } from './share/dialog/policy-cancellation-upload/policy-cancellation-upload.component';

@NgModule({
	entryComponents: [SingleTextInputDialog, ConfirmationDialog],
	declarations: [
		AppComponent,
		AppBlankComponent,
		AppFullComponent,
		AppHeaderComponent,
		AppDrawerComponent,
		ConfirmationDialog,
		SingleTextInputDialog,
		AppDrawerDirective,
		PolicyCancellationLogComponent,
		PolicyCancellationUploadComponent,
	],
	imports: [
		SharedComponentModule,
		BrowserModule,
		AppRoutingModule,
		BrowserAnimationsModule,
		MaterialModule,
		FlexLayoutModule,
		AuthenticationModule,
		DashboardModule,
		HttpClientModule,
		NgxSpinnerModule,
		ClipboardModule,
		FormsModule,
		ReactiveFormsModule,
		NgxMatSelectSearchModule,
		NgxMaskModule.forRoot(),
		ServiceWorkerModule.register("/ngsw-worker.js", {
			enabled: environment.production,
		}),
	],
	providers: [
		{ provide: MAT_DATE_LOCALE, useValue: "en-MY" },
		{
			provide: HTTP_INTERCEPTORS,
			useClass: HttpRequestInterceptor,
			multi: true,
		},
	],
	bootstrap: [AppComponent],
})
export class AppModule {}
